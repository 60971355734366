import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Layout from "../components/Layout"
import { SEO } from "../components/seo"

export default function Progetti() {
  return (
    <Layout>
      <div className="two-rows">
        <div className="span-all-rows">
          A Mergoscia Moreno Wildhaber coltiva circa 8000 metri quadrati di
          vigna, quasi essenzialmente uva americana, con la quale produce
          distillati nel suo alambicco privato risalente al 1896. Nella
          viticultura, cosÌ come nella selvicoltura e nell’architettura è
          importante essere lungimiranti, prevedere e prevenire i problemi che
          potrebbero manifestarsi a breve e a lungo termine.
        </div>

        <div className="distillation-text-left">
          <strong>Astuzia</strong>
          <div>
            distillato di vinaccia e mele
            <br />
            Vol. 47%
            <br />
            bottiglie da 0,5l
            <br />
            prezzo 25 CHF
            <br />
            <br />
            <a
              className="greyBoldLink"
              href="/certificates/Distisuisse_23-24_Astuzia.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              DistiSuisse 2023/24 Medaglia d'argento
            </a>
          </div>
        </div>
        <StaticImage
          src={"../assets/images/Distillazione/astuzia.jpg"}
          aspectRatio={2 / 3}
          alt="Astuzia"
        />

        {/* <div
          style={{
            padding: "2rem",
            border: "solid",
            borderWidth: "0.5rem",
            borderColor: "black",
          }}
        >
          A Mergoscia Moreno Wildhaber coltiva circa 8000 metri quadrati di
          vigna, quasi essenzialmente uva americana, con la quale produce
          distillati nel suo alambicco privato risalente al 1896. Nella
          viticultura, cosÌ come nella selvicoltura e nell’architettura è
          importante essere lungimiranti, prevedere e prevenire i problemi che
          potrebbero manifestarsi a breve e a lungo termine.
        </div>

        <div /> */}

        <StaticImage
          src={"../assets/images/Distillazione/pacenza.jpg"}
          aspectRatio={2 / 3}
          alt="Pancenza"
        />
        <div className="distillation-text-right">
          <strong>Pacenza</strong>
          <div>
            distillato di vino d’uva americana affinato in botti di rovere
            <br />
            Vol. 44%
            <br />
            bottiglie da 0,5l
            <br />
            prezzo 40 CHF
          </div>
        </div>

        <div className="distillation-text-left">
          <strong>Creanza</strong>
          <div>
            distillato di vino d’uva americana
            <br />
            Vol. 42%
            <br />
            bottiglie da 0,5l
            <br />
            prezzo 35 CHF
            <br />
            <br />
            <a
              className="greyBoldLink"
              href="/certificates/Distisuisse_23-24_Creanza.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              DistiSuisse 2023/24 Medaglia d'oro
            </a>
          </div>
        </div>
        <StaticImage
          src={"../assets/images/Distillazione/creanza.jpg"}
          aspectRatio={2 / 3}
          alt="Creanza"
        />

        <StaticImage
          src={"../assets/images/Distillazione/tolleranza.jpg"}
          aspectRatio={2 / 3}
          alt="Astuzia"
        />
        <div className="distillation-text-right">
          <strong>Tolleranza</strong>
          <div>
            infuso di bacche estive e spezie in un distillato di vino liquoroso
            <br />
            Vol. 36%
            <br />
            bottiglie da 0,5l
            <br />
            prezzo 30 CHF
          </div>
        </div>

        <div className="distillation-text-left">
          <strong>Tropino Classica</strong>
          <div>
            ricavata da vinacce d'uva americana
            <br />
            Vol. 48%
            <br />
            bottiglie da 1l
            <br />
            prezzo 40 CHF
          </div>
        </div>
        <StaticImage
          src={"../assets/images/Distillazione/classica.jpg"}
          aspectRatio={2 / 3}
          alt="Astuzia"
        />
        {/* <StaticImage
          src={"../assets/images/Distillazione/pacenza.jpg"}
          aspectRatio={2 / 3}
          alt="Pancenza"
        />
        <div className="distillation-text-right">
          <strong>Pacenza</strong>
          <div>
            distillato di vino d’uva americana affinato in botti di rovere
            <br />
            Vol. 44%
            <br />
            bottiglie da 0,5l
            <br />
            prezzo 40 CHF
          </div>
        </div>
       */}
        <StaticImage
          src={"../assets/images/Distillazione/alambicco.png"}
          aspectRatio={2 / 3}
          alt="Astuzia"
        />
        <div />

        <div />
        <div className="single-project-picture-landscape">
          <StaticImage
            src={"../assets/images/Distillazione/agricultura2.jpg"}
            // aspectRatio={2 / 3}
            alt="Astuzia"
          />
        </div>

        <div />
        <div />
        {/* <div />
        <StaticImage
          src={"../assets/images/Distillazione/ellia.jpg"}
          aspectRatio={2 / 3}
          alt="Astuzia"
        /> */}
        <div className="single-project-picture-landscape">
          <StaticImage
            src={"../assets/images/Distillazione/agricultura1.jpg"}
            // aspectRatio={2 / 3}
            alt="Astuzia"
          />
        </div>
        <div />
        <div />
        <div className="single-project-picture-landscape">
          <StaticImage
            src={"../assets/images/Distillazione/agricultura3.jpg"}
            // aspectRatio={2 / 3}
            alt="Astuzia"
          />
        </div>
        <div />
        <div />
        <div className="single-project-picture-landscape">
          <StaticImage
            src={"../assets/images/Distillazione/agricultura4.png"}
            // aspectRatio={2 / 3}
            alt="Astuzia"
          />
        </div>
        <div />
        <div />
      </div>
    </Layout>
  )
}

export const Head = () => <SEO />
